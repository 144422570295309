var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "modal fade",
    attrs: {
      "id": "contactdetail",
      "tabindex": "-1",
      "role": "dialog",
      "aria-labelledby": "contactdetail",
      "aria-hidden": "true"
    }
  }, [_c('div', {
    staticClass: "modal-dialog modal-dialog-centered",
    attrs: {
      "role": "document"
    }
  }, [_c('div', {
    staticClass: "modal-content"
  }, [_vm._m(0), _vm._m(1), _c('div', {
    staticClass: "modal-body m-0 p-0"
  }, [_vm._m(2), _c('div', {
    staticClass: "modal__footer__btns"
  }, [_c('button', {
    staticClass: "cancel",
    on: {
      "click": _vm.deleteAction
    }
  }, [_vm._v("Delete")]), _c('button', {
    staticClass: "cancel",
    on: {
      "click": _vm.editAction
    }
  }, [_vm._v("Edit")]), _c('el-button', {
    attrs: {
      "type": "primary",
      "id": "cancelinput"
    },
    on: {
      "click": _vm.hideModal
    }
  }, [_vm._v("Cancel")])], 1)])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "contact_image pt-4 pb-2"
  }, [_c('i', {
    staticClass: "fas fa-user fa-5x",
    staticStyle: {
      "color": "#230B34"
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "modal-header pt-4 pb-2"
  }, [_c('h3', {
    staticClass: "contact_detail_header"
  }, [_vm._v(" Malik ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "contact_main_content"
  }, [_c('p', {
    staticClass: "contact_text_detail_header"
  }, [_vm._v(" Phone ")]), _c('p', {
    staticClass: "contact_detail_text"
  }, [_vm._v(" 0249809695 ")]), _c('p', {
    staticClass: "contact_text_detail_header"
  }, [_vm._v(" Date of birth ")]), _c('p', {
    staticClass: "contact_detail_text"
  }, [_vm._v(" 22/04/1998 ")]), _c('p', {
    staticClass: "contact_text_detail_header"
  }, [_vm._v(" Groups ")]), _c('p', {
    staticClass: "contact_detail_text detail_122"
  }, [_vm._v(" 22/04/1998 ")])]);
}];
export { render, staticRenderFns };