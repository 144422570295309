import store from '@/state/store.js';
let $ = require('jquery');
export default {
  name: 'contact-detail',
  data() {
    return {
      inputFormData: {
        name: '',
        description: ''
      },
      rules: {
        name: [{
          required: true,
          message: 'Group name is required',
          trigger: 'change'
        }]
      },
      continueModalContent: {
        title: "",
        text: '',
        type: ''
      }
    };
  },
  computed: {
    contactDetail() {
      return store.getters['uistate/contactDetail'];
    }
  },
  methods: {
    deleteAction() {
      $('#contactdetail').modal('hide');
      setTimeout(function () {
        $('#continuedit').modal('show');
      }, 1000);
      this.continueModalContent = {
        title: 'Editing contact would permanently edit from all associated  groups',
        text: "Contact is in Special listing, Group 2, Group 3, Group 8",
        type: 'delete'
      };
    },
    editAction() {
      $('#contactdetail').modal('hide');
      setTimeout(function () {
        $('#continuedit').modal('show');
      }, 1000);
      this.continueModalContent = {
        title: 'Editing contact would permanently edit from all associated  groups',
        text: "Contact is in Special listing, Group 2, Group 3, Group 8",
        type: 'edit'
      };
    },
    continueEditAction() {},
    hideContinueEditModal() {
      $('#continuedit').modal('hide');
    },
    continueDeleteAction() {},
    hideModal() {
      $('#contactdetail').modal('hide');
      this.inputFormData = {
        name: '',
        description: ''
      };
    }
  }
};