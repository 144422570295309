<template>
  <div class="scrollable">
    <div class="__wrapper" v-loading="fetchingData">
      <div
        class="__delete_selected"
        v-if="
          Array.isArray(tableSelectContacts) && tableSelectContacts.length > 0
        ">
        <div class="__delete_selected__select__text">
          <strong>{{ tableSelectContacts.length }} selected</strong>
        </div>
        <el-button
          :loading="loading"
          type="danger"
          @click="deleteMultiple"
          class="__delete_selected__delete">
          {{ $t("misc.button.delete") }}
        </el-button>
        <button
          class="__delete_selected__cancel notranslate"
          @click="cancelDeleteMultiple">
          {{ $t("misc.button.cancel") }}
        </button>
      </div>
      <div class="search_filter">
        <div class="search-container">
          <el-input
            :placeholder="$t('dashboard.contacts.search.placeholder')"
            class="search_item"
            v-model="search"
            clearable
            @clear="fetchDataAfterRefresh"
            @input="testChange">
            <i
              slot="prefix"
              style="padding-bottom: 1em"
              class="el-input__icon el-icon-search"></i>
          </el-input>
          <el-button
            class="search_button"
            type="primary"
            :disabled="search == ''"
            @click.prevent="searchContact"
            :loading="buttonLoading"
            >{{ $t("dashboard.contacts.search.label") }}</el-button
          >
        </div>
      </div>

      <div class="groups mnotify-card" style="padding: 0px !important">
        <div
          class="mnotify-card-header d-flex align-items-center"
          style="padding-left: 2em">
          <div class="group-details">
            <button
              @click="
                $router.push('/user/contacts').catch((err) => {
                  if (err.name !== 'NavigationDuplicated') throw err;
                })
              "
              class="backk__btn">
              <img
                width="14px"
                height="14px"
                class="mr-2"
                src="@/assets/arraow__back.svg"
                alt="arrow icon" />
              {{ $t("misc.button.back") }}
            </button>
            <h3 class="mnotify-card-header-text">
              {{ $t("dashboard.groups.groups") }}
              {{
                `(${
                  contacts != 0 && contacts.length > 0 ? contacts.length : 0
                })`
              }}
            </h3>
          </div>
          <div class="export-container">
            <el-button
              class="export_button"
              type="primary"
              @click="exportContactsInGroup"
              :loading="exportLoading"
              >{{ $t("dashboard.contacts.export") }}</el-button
            >
          </div>
        </div>
        <div class="group-name">
          <h3>{{ groupName }}</h3>
        </div>
        <div class="card-inner" v-if="contacts == 0">
          <div class="no-data">
            <h3 class="empty_header">
              {{ $t("dashboard.send_message.sms.group_sms.no_contact") }}
            </h3>
          </div>
        </div>
        <div class="contact_table" v-if="contacts != 0">
          <el-table
            :header-cell-style="{ background: 'rgba(212, 216, 226, 0.1)' }"
            :data="displayData"
            ref="singleTable"
            :row-style="handleRowStyle"
            :key="refreshKey"
            :scrollbar-always-on="true"
            v-loading="tableLoading"
            height="52vh">
            <!-- <el-table-column width="10px">
                            <template slot-scope="scope">
                                <button class="__select_active" v-if="!scope.row.active"
                                    @click="handleSelect(scope, scope.$index)">
                                    <img height="15px" src="../../../assets/contact_unselect.svg" alt="">
                                </button>
                                <button class="__select_active" v-if="scope.row.active"
                                    @click="handleDeselect(scope, scope.$index)">
                                    <img height="15px" src="@/assets/_contacts_check.svg" alt="">
                                </button>
                            </template>
                        </el-table-column> -->
            <el-table-column
              :label="$t('dashboard.send_message.international_messages.name')"
              width="25%">
              <template slot-scope="scope">
                {{ scope.row.firstname }} {{ scope.row.lastname }}
              </template>
            </el-table-column>
            <el-table-column
              prop="phone"
              :label="$t('dashboard.contacts.mobile')"
              width="25%">
            </el-table-column>
            <!-- <el-table-column prop="group" label="Groups" width="40%">
                        </el-table-column> -->
            <el-table-column :label="$t('misc.table.actions')" width="20%">
              <template slot-scope="scope">
                <button
                  class="__contact__icon"
                  @click="
                    opendModal({
                      contact: scope.row,
                      key: scope.$index,
                      type: 'detail',
                    })
                  ">
                  <img
                    height=""
                    src="../../../assets/Profile.svg"
                    alt="profile icon" />
                </button>
                <button
                  @click="
                    opendModal({
                      contact: scope.row,
                      key: scope.$index,
                      type: 'edit',
                    })
                  "
                  class="__contact__icon">
                  <img src="../../../assets/Edit Square.svg" alt="edit icon" />
                </button>
                <button
                  @click="
                    opendModal({
                      contact: scope.row,
                      key: scope.$index,
                      type: 'delete',
                    })
                  "
                  class="__contact__icon __contact__delete">
                  <img
                    src="../../../assets/Delete (1).svg"
                    alt="bin icon for delete" />
                </button>
              </template>
            </el-table-column>
          </el-table>
          <div class="" style="text-align: center; margin-top: 2em">
            <el-pagination
              background
              layout="prev, pager, next"
              @current-change="handleCurrentChange"
              :page-size="pageSize"
              :total="total">
            </el-pagination>
          </div>
        </div>
      </div>
      <!--MODAL CONTENTS -->
      <!-- DELETE, UPDATE AND VIEW CONTACT MODAL  START -->
      <div
        class="modal fade"
        id="deletecontact"
        tabindex="-1"
        role="dialog"
        aria-labelledby="contact"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content d-flex align-items-center" v-if="success">
            <successMessage :message="success" />
            <button
              @click="hideMainModal('reload')"
              class="__close__success__modal">
              {{ $t("misc.button.close") }}
            </button>
          </div>
          <div class="modal-content pb-3" v-if="!success">
            <div class="modal-body m-0 p-0">
              <!-- DELETE CONTACT SECTION -->
              <div
                class="row justify-content-center"
                style="padding-right: 1em; padding-left: 1em"
                v-if="currentModal === 'delete'">
                <div class="continue_edit_header pb-2">
                  <div class="_edit_icon">
                    <img
                      :src="deletEditModalContent.icon"
                      height="33px"
                      width="31px"
                      alt="bin icon for delete" />
                  </div>
                  <h3 class="continue_edit_header_title">
                    {{ deletEditModalContent.title }}
                  </h3>
                  <p class="__contacts_in_group">
                    {{ deletEditModalContent.text }}
                  </p>
                </div>
                <div
                  class="col-md-12"
                  style="padding-left: 3em; padding-right: 3em">
                  <Alert v-if="errors" :message="errors" :type="type" />
                </div>
              </div>
              <div class="modal__footer__btns" v-if="currentModal === 'delete'">
                <div class="deleteWrapper">
                  <el-button
                    @click="deleteContact"
                    v-if="deletEditModalContent.type == 'delete'"
                    style="
                      margin-right: 1.5em;
                      padding-right: 2em;
                      padding-left: 2em;
                    "
                    :loading="loading"
                    type="danger">
                    {{ $t("misc.button.delete") }}
                  </el-button>
                  <button
                    v-if="deletEditModalContent.type == 'continue_edit'"
                    style="
                      margin-right: 1.5em;
                      padding-right: 2em;
                      padding-left: 2em;
                    "
                    class="cancel"
                    @click="editForm">
                    {{ $t("misc.button.edit") }}
                  </button>
                  <el-button
                    style="
                      margin-right: 1.5em;
                      padding-right: 2em;
                      padding-left: 2em;
                    "
                    class="notranslate"
                    @click="hideMainModal"
                    type="primary">
                    {{ $t("misc.button.cancel") }}
                  </el-button>
                </div>
              </div>
            </div>
            <div
              class="row justify-content-center"
              style="padding-right: 1em; padding-left: 1em"
              v-if="currentModal === 'detail'">
              <div class="edit_contact_detail">
                <div class="contact_image pt-4 pb-2 contact-image-edit">
                  <img
                    src="../../../assets/profile_lg.svg"
                    alt="profile icon" />
                </div>
                <div v-if="isEdit" class="modal-header pt-4 pb-2">
                  <h3 class="contact_detail_header">
                    {{ contactDetail.firstname }} {{ contactDetail.lastname }}
                  </h3>
                </div>
                <div class="modal-body m-0 p-0">
                  <div class="contact_main_content" v-if="isEdit">
                    <p class="contact_text_detail_header">
                      {{
                        $t(
                          "dashboard.send_message.message_templates.form.content.phone"
                        )
                      }}
                    </p>
                    <p class="contact_detail_text">
                      {{ contactDetail.phone }}
                    </p>
                    <p class="contact_text_detail_header">
                      {{ $t("dashboard.contacts.date_of_birth") }}
                    </p>
                    <p class="contact_detail_text">
                      {{
                        contactDetail.date_of_birth == "0000-00-00"
                          ? "Not set"
                          : `${contactDetail.date_of_birth.split("-")[2]}/${
                              contactDetail.date_of_birth.split("-")[1]
                            }/${contactDetail.date_of_birth.split("-")[0]}`
                      }}
                    </p>
                    <p class="contact_text_detail_header">
                      {{ $t("dashboard.groups.groups") }}
                    </p>
                    <!-- <p class="contact_detail_text_groups">
                                            {{ groupName }}
                                        </p> -->
                  </div>
                  <div class="mt-3" v-if="!isEdit">
                    <div class="col-md-12">
                      <el-form
                        :model="formData"
                        ref="ruleForm"
                        :rules="rules"
                        @submit.prevent.native="checkEnter">
                        <div class="row justify-content-center">
                          <div class="col-6 col-sm-6 col-md-6">
                            <div class="form_input_container">
                              <el-form-item
                                :label="
                                  $t(
                                    'dashboard.send_message.message_templates.form.content.first_name'
                                  )
                                "
                                prop="firstname">
                                <el-input
                                  type="text"
                                  autocomplete="off"
                                  :placeholder="
                                    $t(
                                      'dashboard.send_message.message_templates.form.content.first_name'
                                    )
                                  "
                                  v-model="formData.firstname"></el-input>
                              </el-form-item>
                            </div>
                          </div>
                          <div class="col-6 col-sm-6 col-md-6">
                            <div class="form_input_container">
                              <el-form-item
                                :label="
                                  $t(
                                    'dashboard.send_message.message_templates.form.content.last_name'
                                  )
                                "
                                prop="lastname">
                                <el-input
                                  type="text"
                                  autocomplete="off"
                                  :placeholder="
                                    $t(
                                      'dashboard.send_message.message_templates.form.content.last_name'
                                    )
                                  "
                                  v-model="formData.lastname"></el-input>
                              </el-form-item>
                            </div>
                          </div>
                        </div>
                        <div class="row justify-content-center">
                          <div class="col-md-12 my-0 py-0">
                            <el-form-item
                              style="margin: 0; padding: 0"
                              :label="
                                $t('dashboard.contacts.date_of_birth')
                              "></el-form-item>
                          </div>
                          <div class="col-md-12">
                            <div class="form_input_container mb-2">
                              <el-form-item
                                style="width: 100%; margin: 0; padding: 0"
                                prop="date_of_birth">
                                <el-date-picker
                                  style="width: 100%"
                                  v-model="formData.date_of_birth"
                                  type="date"
                                  format="yyyy-MM-dd"
                                  placeholder="1999-02-05"
                                  popper-class="notranslate">
                                </el-date-picker>
                              </el-form-item>
                            </div>
                          </div>
                          <!-- <div class="col-md-12 my-0 py-0">
                                                                <el-form-item style="margin:0; padding:0;" required label="Date of Birth"></el-form-item>
                                                            </div>
                                                            <div class="col-4 col-sm-4 col-md-4">
                                                                <div class="form_input_container">
                                                                    <el-form-item  prop="day">
                                                                        <el-date-picker
                                                                            style="width:100%;"
                                                                            v-model="formData.day"
                                                                            type="date"
                                                                            format="dd"
                                                                            placeholder="Day">
                                                                        </el-date-picker>
                                                                    </el-form-item>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-sm-4 col-md-4">
                                                                <div class="form_input_container">
                                                                    <el-form-item  prop="month">
                                                                        <el-date-picker
                                                                            style="width:100%"
                                                                            v-model="formData.month"
                                                                            type="month"
                                                                            format="MM"
                                                                            placeholder="Month">
                                                                        </el-date-picker>
                                                                    </el-form-item>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-sm-4 col-md-4">
                                                                <div class="form_input_container">
                                                                    <el-form-item  prop="year">
                                                                        <el-date-picker
                                                                            v-model="formData.year"
                                                                            type="year"
                                                                            style="width:100%"
                                                                            format="yyyy"
                                                                            placeholder="Year">
                                                                        </el-date-picker>
                                                                    </el-form-item>
                                                                </div>
                                                            </div> -->
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form_input_container">
                              <el-form-item
                                :label="$t('misc.phone_input.phoneNumberLabel')"
                                prop="phone">
                                <el-input
                                  type="text"
                                  autocomplete="off"
                                  :placeholder="
                                    $t('misc.phone_input.phoneNumberLabel')
                                  "
                                  v-model="formData.phone"></el-input>
                              </el-form-item>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form_input_container">
                              <el-form-item
                                :label="
                                  $t(
                                    'dashboard.onboarding.verify_email.form.email.label'
                                  )
                                "
                                prop="email">
                                <el-input
                                  type="email"
                                  autocomplete="off"
                                  :placeholder="
                                    $t(
                                      'dashboard.onboarding.verify_email.form.email.label'
                                    )
                                  "
                                  v-model="formData.email"></el-input>
                              </el-form-item>
                            </div>
                          </div>
                        </div>
                      </el-form>
                    </div>
                  </div>
                  <div v-if="isEdit" class="modal__footer__btns mx-3">
                    <button
                      style="width: 123px"
                      class="cancel"
                      @click="deleteAction">
                      {{ $t("misc.button.delete") }}
                    </button>
                    <button
                      style="width: 123px"
                      class="cancel"
                      @click="continueEdit">
                      {{ $t("misc.button.edit") }}
                    </button>
                    <el-button
                      style="width: 123px"
                      class="notranslate"
                      type="primary"
                      id="cancelinput"
                      @click="hideMainModal"
                      >{{ $t("misc.button.cancel") }}</el-button
                    >
                  </div>
                  <div v-if="!isEdit" class="modal__footer__btns mx-3">
                    <button style="width: 123px" class="cancel" @click="back">
                      {{ $t("misc.button.back") }}
                    </button>
                    <el-button
                      style="width: 123px"
                      type="primary"
                      :loading="loading"
                      id="cancelinput"
                      @click="save('ruleForm')"
                      >{{ $t("misc.button.save") }}</el-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- DELETE,UPDATE AND VIEW CONTACT MODAL END -->
      <contactModal ref="contactModal" />
      <importContactFromExcel
        @cancel="cancelImportFromExcel"
        ref="importExcel" />
    </div>
  </div>
</template>

<script>
  import store from "@/state/store.js";
  import $ from "jquery";
  import contactModal from "./createNewContact.vue";
  import contactDetail from "./contactDetail.vue";
  import formatInputDate from "@/helpers/formatInputDate";
  import importContactFromExcel from "./importContactFromExcel.vue";
  import downloadcsv from "../../../helpers/downloadcsv";
  export default {
    name: "contacts",
    components: {
      importContactFromExcel,
      contactModal,
      contactDetail,
    },
    data() {
      return {
        refreshKey: 0,
        showModal: false,
        loading: false,
        fetchingData: false,
        currentModal: "",
        errors: "",
        page: 1,
        pageSize: 50,
        total: 0,
        deletEditModalContent: {},
        rules: {
          firstname: [
            {
              required: true,
              message: this.$t("misc.form.required"),
              trigger: "change",
            },
          ],
          lastname: [
            {
              required: true,
              message: this.$t("misc.form.required"),
              trigger: "change",
            },
          ],
          // date_of_birth: [
          //     {required: true, message: 'Please this field is required', trigger: 'change' },
          // ],
          // email: [
          //     {required: true, message: 'Please this field is required', trigger: 'change' },
          // ],
          phone: [
            {
              required: true,
              pattern: /^[0-9]{10}$/g,
              message: this.$t("misc.form.required"),
            },
          ],
        },
        formData: {
          firstname: "",
          lastname: "",
          date_of_birth: "",
          name: "",
          email: "",
          phone: "",
          id: "",
          key: "",
        },
        isEdit: true,
        search: "",
        contactError: [],
        contactSuccess: "",
        success: "",
        conctactLoading: false,
        type: "alert-danger",
        modalData: {
          title: "",
          formData: {},
          btnTitle: "",
        },
        selectedContact: {
          key: "",
          contact_id: "",
          group_id: "",
        },
        contactDetail: {},
        tableSelectContacts: [],
        formData: {},
        groupName: "",
        contacts: [],
        tableLoading: false,
        buttonLoading: false,
        exportLoading: false,
      };
    },
    created() {
      // this.getGroupData(this.$route.params.id)
    },
    computed: {
      fetchedGroups() {
        return store.getters["groups/all_groups"];
      },
      group() {
        return store.getters["contacts/singleGroupContacts"];
      },
      searching() {
        return this.contacts;
      },
      displayData() {
        return this.searching;
      },
    },
    methods: {
      getGroupData(page) {
        this.tableLoading = true;
        store
          .dispatch("groups/getGroup", {
            id: this.$route.params.id,
            page: page,
          })
          .then((response) => {
            this.fetchingData = false;
            let group = response.data.data;
            this.groupName = group.name;
            this.contacts = group.contact.data;
            this.contacts.forEach((contact) => {
              contact.group = group.name;
              contact.active = false;
            });
            this.total = group.contact.total;
          })
          .catch((err) => {
            this.fetchingData = false;
          })
          .finally(() => {
            this.tableLoading = false;
          });
      },
      handleCurrentChange(val) {
        this.page = val;
        this.getGroupData(val);
      },
      handleRowStyle({ row, rowIndex }) {
        return this.contacts && this.contacts[rowIndex].active
          ? { background: "rgba(207, 214, 233, 0.19)" }
          : "";
      },
      cancelDeleteMultiple() {
        this.contacts.forEach((contact) => (contact.active = false));
        this.contacts = [...this.contacts];
        this.tableSelectContacts = [];
      },
      handleSelect(row) {
        this.contacts[row.$index].active = !this.contacts[row.$index].active;
        this.contacts = [...this.contacts];
        this.tableSelectContacts.push(row.row.id);
      },
      handleDeselect(row) {
        this.contacts[row.$index].active = !this.contacts[row.$index].active;
        this.contacts = [...this.contacts];
        this.tableSelectContacts.pop(row.row.id);
      },
      addNewContact() {
        let element = this.$refs.contactModal.$el;
        $(element).modal("show");
        store.dispatch("uistate/addContactFormData", {
          title: this.$t("dashboard.contacts.add_new"),
          formData: {},
          btnTitle: this.$t("dashboard.contacts.add_contact"),
        });
      },
      back() {
        this.deletEditModalContent = {
          title: this.$t("dashboard.contacts.editng_heading"),
          text: `${this.groupName}`,
          type: "continue_edit",
          icon: require("../../../assets/edit__.svg"),
        };
        this.currentModal = "delete";
      },
      importFromExcel() {
        store.dispatch("uistate/addGroupFormData", {
          title: "",
          mainModalType: "import",
          type: "import",
          btnTitle: this.$t("dashboard.contacts.import_excel_sheet"),
          importExcelContentType: "main",
        });
        let element = this.$refs.importExcel.$el;
        $(element).modal("show");
      },
      cancelImportFromExcel() {
        store.dispatch("uistate/addGroupFormData", {});
        let element = this.$refs.importExcel.$el;
        $(element).modal("hide");
      },
      editForm() {
        this.currentModal = "detail";
        this.isEdit = false;
        this.loading = false;
        this.errors = "";
        this.success = "";
      },
      deleteAction() {
        this.loading = false;
        this.errors = "";
        this.success = "";
        this.currentModal = "delete";
        this.deletEditModalContent = {
          title: this.$t("dashboard.contacts.delete_heading"),
          text: this.$t("dashboard.contacts.contact_group", {
            group: this.groupName,
          }),
          type: "delete",
          icon: require("../../../assets/Delete.svg"),
        };
      },
      continueEdit() {
        this.deletEditModalContent = {
          title: this.$t("dashboard.contacts.editng_heading"),
          text: this.$t("dashboard.contacts.contact_group", {
            group: this.groupName,
          }),
          type: "continue_edit",
          icon: require("../../../assets/edit__.svg"),
        };
        this.currentModal = "delete";
      },
      hideMainModal(type = "something") {
        $("#deletecontact").modal("hide");
        this.success = false;
        this.errors = "";
        this.currentModal = "";
        this.isEdit = true;
        this.loading = false;
        this.contactDetail = {};
        this.deletEditModalContent = {};
        $("#deletecontact").modal("hide");
        this.getGroupData(this.page);
      },
      opendModal({ contact, key, type }) {
        $("#deletecontact").modal("show");
        if (type === "delete") {
          this.deletEditModalContent = {
            title: this.$t("dashboard.contacts.delete_heading"),
            text: this.$t("dashboard.contacts.contact_group", {
              group: this.groupName,
            }),
            type: "delete",
            icon: require("../../../assets/Delete.svg"),
          };
          this.currentModal = "delete";
        } else if (type == "edit") {
          this.deletEditModalContent = {
            title: this.$t("dashboard.contacts.editng_heading"),
            text: this.$t("dashboard.contacts.contact_group", {
              group: this.groupName,
            }),
            type: "continue_edit",
            icon: require("../../../assets/edit__.svg"),
          };
          this.currentModal = "delete";
        } else if (type === "detail") {
          this.currentModal = "detail";
        }
        this.contactDetail = contact;
        this.selectedContact = {
          key: key,
          contact_id: contact.id,
          group_id: contact.group_id,
        };
        // updat edit form component
        let date =
          contact.date_of_birth == "0000-00-00"
            ? ""
            : formatInputDate(contact.date_of_birth);
        this.formData = {
          firstname: contact.firstname,
          lastname: contact.lastname,
          email: contact.email,
          phone: contact.phone,
          // date_of_birth:contact.date_of_birth,
          date_of_birth: date,
          id: contact.id,
          key: key,
        };
      },
      deleteMultiple() {
        this.errors = "";
        this.loading = true;
        this.success = "";
        let payload = {
          contact_ids: this.tableSelectContacts.toString(),
          key: this.selectedContact.key,
          group_id: this.selectedContact.group_id,
        };
        store
          .dispatch("contacts/deleteContacts", payload)
          .then((response) => {
            this.loading = false;
            if (response) {
              this.loading = false;
              this.success = response.data.message;
              $("#deletecontact").modal("show");
              this.tableSelectContacts = [];
            }
          })
          .catch((err) => {
            this.loading = false;
            if (err.response.data == undefined) {
              this.errors = this.$t("misc.network_error");
            }
            if (err.response && err.response.status === 404) {
              this.loading = false;
              this.errors = err.response.data.data.error;
            }
          });
      },
      deleteContact() {
        this.errors = "";
        this.tableLoading = true;
        let payload = {
          contact_id: this.selectedContact.contact_id,
          key: this.selectedContact.key,
          group_id: this.selectedContact.group_id,
        };
        store
          .dispatch("contacts/deleteContact", payload)
          .then((response) => {
            if (response.data.message == "Contact Exist in other groups") {
              this.errors = response.data.message;
            } else {
              this.success = response.data.message;
            }
            this.tableLoading = false;
          })
          .catch((err) => {
            this.tableLoading = false;
            if (err.response.data == undefined) {
              this.errors = this.$t("misc.network_error");
            }
            if (err.response && err.response.status === 404) {
              this.tableLoading = false;
              this.errors = err.response.data.data.error;
            }
          });
      },
      save(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.errors = "";
            this.loading = true;
            let { date_of_birth, email, phone, firstname, lastname, id, key } =
              this.formData;
            let date =
              date_of_birth == null
                ? "0000-00-00"
                : formatInputDate(date_of_birth);
            let payload = {
              data: {
                firstname: firstname,
                lastname: lastname,
                date_of_birth: date,
                phone: phone,
                email: email,
              },
              id: id,
              key: key,
            };
            store
              .dispatch("contacts/updateContact", payload)
              .then((response) => {
                let data = response.data.data;
                this.contacts[this.formData.key] = data;
                this.contacts = [...this.contacts];
                this.loading = false;
                this.success = response.data.message;
              })
              .catch((err) => {
                this.loading = false;
                if (err.response?.data == undefined) {
                  this.errors = this.$t("misc.network_error");
                }
                if (err.response && err.response.status === 404) {
                  this.errors = err.response.data.data.error;
                }
              });
          } else {
            this.loading = false;
            return false;
          }
        });
      },
      searchContact() {
        this.tableLoading = true;
        this.buttonLoading = true;
        let payload = {
          search: this.search,
          group_id: this.$route.params.id,
        };
        store
          .dispatch("groups/searchContactInGroup", payload)
          .then((response) => {
            this.contacts = response.data;
            this.total = response.data.length;
            this.tableLoading = false;
            this.buttonLoading = false;
            if (response.data.length == 0) {
              this.$notify({
                title: this.$t("misc.toast.warning"),
                message: this.$t("dashboard.contacts.no_result_title"),
                type: "warning",
              });
            }
          })
          .catch((err) => {
            this.tableLoading = false;
            this.buttonLoading = false;
          })
          .finally(() => {
            this.tableLoading = false;
            this.buttonLoading = false;
          });
      },
      fetchDataAfterRefresh() {
        this.getGroupData(1);
      },
      testChange(value) {
        if (value == "") {
          this.fetchDataAfterRefresh();
        } else {
          return;
        }
      },
      exportContactsInGroup() {
        this.exportLoading = true;
        store
          .dispatch("groups/exportGroup", {
            id: this.$route.params.id,
          })
          .then((data) => {
            downloadcsv(
              data.data,
              `${this.groupName.replace(/ /g, "_")}_Contacts.csv`
            );
            this.$notify({
              title: this.$t("misc.toast.success"),
              message: this.$t("dashboard.contacts.export_successful"),
              type: "success",
            });
          })
          .catch((err) => {
            this.$notify({
              title: this.$t("misc.toast.error"),
              message: this.$t("dashboard.contacts.export_error"),
              type: "error",
            });
          })
          .finally(() => {
            this.exportLoading = false;
          });
      },
    },
    mounted() {
      // Analytics
      this.$mixpanel.track("Contacts in Group Page");
      if (this.$store.getters["auth/currentUser"].country === "CI") {
        this.$mixpanelFranco.track("Contacts in Group Page");
      }
    },
    beforeMount() {
      this.fetchingData = true;
      store
        .dispatch("groups/getGroup", { id: this.$route.params.id, page: 1 })
        .then((response) => {
          this.fetchingData = false;
          let group = response.data.data;
          this.groupName = group.name;
          this.contacts = group.contact.data;
          this.contacts.forEach((contact) => {
            contact.group = group.name;
            contact.active = false;
          });
          this.total = group.contact.total;
        })
        .catch((err) => {
          this.fetchingData = false;
        })
        .finally(() => {
          this.tableLoading = false;
          this.fetchingData = false;
        });
    },
  };
</script>
<style scoped>
  @import url("../../../styles/__contacts.scss");

  .backk__btn {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    text-transform: capitalize;
    color: #484a4f;
    background: #f3f3f4;
    border-radius: 22px;
    width: 74px;
    height: 27px;
    outline: none;
    border: none;
    margin-right: 1em;
  }

  .contact-image-edit {
    height: 15vh;
  }

  .group-name {
    width: 92%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-block: 1rem;
  }

  .group-name > h3 {
    font-size: clamp(1rem, 1.5vw, 1.5rem);
    font-weight: 900;
  }

  .scrollable {
    overflow-y: hidden;
    height: 100%;
  }

  .search_button:disabled {
    background-color: #f3f3f4;
    color: #cdcdce;
    border: 1px solid #f3f3f4;
  }

  .search-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }

  .group-details {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 2rem;
  }

  .export-container {
    width: 80%;
    display: flex;
    justify-content: flex-end;
  }
</style>
